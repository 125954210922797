<template>
  <v-img
    src="../assets/image/404.gif"
    contain
    height="100%"
    width="100%"
  ></v-img>
</template>

<script>
export default {
  name: "NotFound",
  data() {
    return {
      // 图片服务器
      // VITE_IMAGE_URL: "http://img.chenss.top",
    };
  },
};
</script>

<style scoped>
.notfound {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.notfound img {
  width: 100%;
  height: 100%;
}
</style>
